import React from 'react'
import { Wrap, Title } from '../components/page/components'
import SEO from '../components/SEO'

const Tour = () => {
  return (
    <Wrap>
      <SEO title="Tour" />
      <Title>Tour</Title>
    </Wrap>
  )
}

export default Tour
